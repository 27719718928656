.container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(#000000, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0px;
  top: 0px;
}
