@import 'src/global/constants.scss';

.content {
  display: flex;
  align-items: center;
}

.iconContainer {
  transform: translateY(-1px);

  .icon {
    font-size: 9px;
  }
}

.label {
  padding-left: $padding1;
}
