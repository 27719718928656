@import 'src/global/constants.scss';

.link {
  flex-shrink: 0;
  color: $themeColor7;

  &:hover {
    color: $themeColor5;
  }

  text-decoration: none;
}
