@import 'src/global/constants.scss';

.formItem {
  min-width: 200px;
}

.tags {
  padding: 0px 4px;
  min-height: 32px;
  border: 1px solid transparent;
  margin-bottom: -8px;

  .tag {
    margin-bottom: 8px;
  }
}
