@import 'src/global/constants.scss';

#interviewerDetail {
  .ant-form-item {
    margin: 0px;
  }

  .ant-form-item-control-input + div {
    font-size: 14px;
    transform: translateY(-6px);
    position: absolute;
    top: 100%;
  }

  .ant-input-textarea::after {
    position: absolute;
    right: 0px;
    top: 100%;
  }
}
