@import 'src/global/constants.scss';

.container {
  padding: $padding2;
}

.header {
  padding-bottom: $padding2;
  display: flex;

  .iconContainer {
    flex-shrink: 0;
    width: $padding2;
    display: flex;
    align-items: center;

    .icon {
      flex-shrink: 0;
      font-size: 12px;

      svg {
        color: $warningColor1 !important;
      }
    }
  }

  .title {
    flex: 1 1;
  }
}

.content {
  padding: 0px $padding2 $padding2;

  .item {
    padding-bottom: $padding1;
    display: flex;
    align-items: center;

    .icon {
      flex-shrink: 0;
      margin-right: $padding1;
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: #000000;
    }

    .text {
      padding-right: $padding1;

      &:last-child {
        padding-right: 0px;
      }
    }
  }
}

.buttonContainer {
  padding: 0px $padding2;
  display: flex;
  justify-content: center;

  .confirmButton {
    width: 150px;
  }
}
