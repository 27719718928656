@import 'src/global/constants.scss';

.section {
  margin-bottom: $padding3;

  .title {
    padding-bottom: $padding2;
    font-size: 16px;
  }
}

.group {
  display: flex;

  .groupLabel {
    flex-shrink: 0;
    padding: $padding1 $padding2;
    width: 170px;
    background-color: $basicColor2;
    border-left: 1px solid $basicColor4;
    border-top: 1px solid $basicColor4;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .groupContent {
    flex: 1 1;
  }
}
