@import 'src/global/constants.scss';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: $padding2;
}

.createButtonContainer {
  flex-shrink: 0;
  padding: 0 $padding4 $padding2;
  display: flex;
  justify-content: flex-end;

  .addIcon {
    font-size: 9px;
  }
}

.editIcon {
  color: $themeColor7;
}

.breakAll {
  word-break: break-all;
}

.maxWidth {
  max-width: 300px;
}
