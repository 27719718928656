@import 'src/global/constants.scss';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .mainContent {
    flex: 1 1;
    padding: $padding2 $padding4;
    overflow-y: auto;

    .bringInContainer {
      display: flex;
      justify-content: end;
    }
  }
}

.saveButtonContainer {
  flex-shrink: 0;
  padding-top: $padding3;
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    padding-top: $padding1;
  }
}
