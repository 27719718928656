@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding2;
}

.borderContainer {
  padding: $padding4;
  border: 2px solid $basicColor5;
}

.item {
  padding-bottom: $padding1;
  display: flex;

  &:last-child {
    padding-bottom: 0px;
  }

  .label {
    width: 100px;
  }
}

// 加分題
.bonusBorderContainer {
  border-color: $themeColor3;

  .label,
  .content {
    color: $themeColor7;
  }
}
