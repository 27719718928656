@import 'src/global/constants.scss';

.icon {
  margin-right: 4px;
  width: 10px;
  height: 10px;

  &.saIcon {
    background-color: $saColor1;
  }

  &.pmIcon {
    background-color: $pmColor1;
  }

  &.omIcon {
    background-color: $omColor1;
  }
}

.classification {
  display: flex;
  position: absolute;
  left: 240px;
  top: 128px;

  .title {
    padding-right: $padding2;
  }

  .item {
    padding-right: $padding2;
    display: flex;
    align-items: center;
  }
}
