@import 'src/global/constants.scss';

.container {
  padding: 0px $padding6 $padding3;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex {
  display: flex;
}

.label {
  padding-right: $padding5;
}

.select {
  margin-right: $padding3;
  width: 280px;
}

.resolveButton {
  width: 180px;
  display: flex;
  justify-content: center;
}
