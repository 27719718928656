@import 'src/global/constants.scss';

#checkDetailPage {
  .ant-input-textarea {
    width: 100%;
    position: relative;

    &::after {
      position: absolute;
      top: 100%;
      right: 0px;
    }
  }
}
