@import 'src/global/constants.scss';

.minContent {
  padding: $padding2 $padding4;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.cascaderSearch {
  flex-shrink: 0;
  padding-bottom: $padding1;
  display: flex;
  align-items: center;

  .label {
    padding-right: $padding1;
    flex-shrink: 0;
    display: flex;
    align-items: center;

    .star {
      padding-right: 2px;
      color: $themeColor7;
    }
  }

  .cascader {
    min-width: 328px;
  }
}

.inputSearch {
  flex-shrink: 0;
  padding-bottom: $padding2;

  .search {
    width: 264px;
  }
}

.resContainer {
  padding: $padding1 0px $padding2;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topic {
  max-width: 360px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.submitContainer {
  padding-top: $padding2;
  display: flex;
  justify-content: center;

  .button {
    width: 400px;
  }
}
