@import 'src/global/constants.scss';

.container {
  flex-shrink: 0;
  padding-top: $padding2;
  display: flex;
  justify-content: center;
}

.rect {
  margin-right: $padding1;
  position: relative;

  &:last-child {
    margin-right: 0px;
  }

  .total {
    margin-left: $padding1;
    width: 200px;
    transform: translateY(-50%);
    position: absolute;
    left: 100%;
    top: 50%;
  }
}

.button {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 11px;
}
