@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding2;
}

.buttons {
  .button {
    margin-right: $padding1;
  }
}
