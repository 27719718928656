@import 'src/global/constants.scss';

.container {
  height: calc(100vh - 60px - 80px - 44px - 46px - 20px);
  padding: 0px $padding4;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.intervalButtonContainer {
  padding: 20px 0px;
  display: flex;
  align-items: center;
}

.lineChartContainer {
  flex-shrink: 0;
  margin-bottom: 20px;
  padding: $padding2 $padding4;
  border: 1px solid #dce0e6;

  .title {
    padding-bottom: $padding2;
    font-size: 16px;
  }

  .chartContainer {
    overflow-y: auto;
  }
}
