@import 'src/global/constants.scss';

.container {
  padding-right: 10px;
  position: relative;

  &::after {
    content: '';
    display: none;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: #cf1322;
    position: absolute;
    right: 7px;
    top: 11px;
  }

  &.active::after {
    display: block;
  }
}
