@import 'src/global/constants.scss';

.container {
  margin-bottom: $padding2;
  padding: $padding2 $padding3;
  background-color: $basicColor2;
  border: 1px solid transparent;

  .title {
    padding-bottom: $padding1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .deleteButton {
      padding: 0px;
      height: fit-content;
      font-size: 24px;
    }
  }

  &.invalidBorder {
    border: 1px solid $themeColor7;
  }
}

.bonusContainer {
  padding-bottom: $padding1;

  .label {
    padding-left: $padding1;
  }
}

.inputContainer {
  padding-bottom: $padding1;
  display: flex;
  align-items: center;

  .label {
    flex-shrink: 0;
    padding-right: $padding1;
  }
}

.weightedContainer {
  padding-bottom: $padding1;
  display: flex;
  align-items: center;

  .icon {
    margin-right: $padding1;
    flex-shrink: 0;
    color: $basicColor5;

    &.active {
      color: $themeColor7;
    }
  }

  .item {
    display: flex;
    align-items: center;

    .weightedInputContainer {
      width: 90px;
    }

    .checkbox {
      margin-right: $padding1;
      // transform: translateY(2px);
    }
  }

  .semicolon {
    padding-left: 5px;
    padding-right: $padding1;
  }
}
