@import 'src/global/constants.scss';

.container {
  min-height: 100%;
  height: 100%;

  .emptyContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .mainContent {
    padding: $padding4;
  }
}

.basic {
  .item {
    padding-bottom: $padding2;
    display: flex;
    align-items: center;
  }

  .select {
    padding-right: $padding2;
    display: flex;
    align-items: center;

    .label {
      padding-right: $padding2;
    }
  }

  .leftButton {
    margin-right: $padding1;
  }
}

.checkFormItems {
  padding-bottom: $padding2;

  .save {
    margin-right: $padding2;
  }
}

.repliedContainer {
  .from {
    padding-bottom: $padding2;
    display: flex;
    align-items: center;

    .label,
    .inputContainer {
      padding-right: $padding2;
    }
  }
}
