@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding3;
  display: flex;
  justify-content: center;
}

.button {
  width: 120px;
}
