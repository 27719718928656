@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding2;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.titleContainer {
  display: flex;
  align-items: center;

  .icon {
    flex-shrink: 0;
    margin-right: $padding2;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $themeColor7;
  }
}

.tableContainer {
  margin: 0px $padding4;
  max-height: calc(100vh - 80px - 52px - $padding2);
  overflow-y: auto;
}
