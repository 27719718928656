@import 'src/global/constants.scss';

.overflow {
  flex: 1 1;
  overflow: auto;
}

.container {
}

.padding {
  padding: 0 $padding4;
}
