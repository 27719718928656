@import 'src/global/constants.scss';

#interviewerDetailPage {
  .ant-tabs-nav {
    margin: 0px;
    background-color: $basicColor9;
  }

  .ant-tabs-nav-list {
    color: $basicColor1;
  }

  .ant-tabs-nav-wrap {
    padding-left: $padding2;
  }

  .ant-tabs-tab-btn {
    font-size: 16px;
    color: $basicColor1 !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: $themeColor4 !important;
  }

  .ant-tabs-ink-bar {
    background-color: $themeColor4 !important;
  }
}
