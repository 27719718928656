@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding2;
  height: calc(100vh - 60px);
  display: flex;
  flex-direction: column;

  .filters {
    padding: 0px $padding4 $padding2;
    flex-shrink: 0;
    display: flex;
    align-items: center;

    .space {
      flex-grow: 1;
      flex-shrink: 1;
    }

    .selects,
    .search,
    .addButton {
      flex-grow: 0;
      flex-shrink: 0;
    }

    .addButton {
      margin-left: 20px;
    }
  }
}
