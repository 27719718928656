@import 'src/global/constants.scss';

.container {
  flex: none !important;
  max-width: 204px !important;
  min-width: 204px !important;
  width: 204px !important;
  height: 100% !important;
  background-color: transparent !important;

  .menu {
    height: 100%;
  }
}
