@import 'src/global/constants.scss';

#customUpload {
  .ant-upload-list {
    color: $themeColor7;
  }

  svg {
    color: $themeColor7;
  }
}
