@import 'src/global/constants.scss';

.mainPadding {
}

.container {
  padding: 0px $padding2;
  border: 1px solid #fafafa;
  box-shadow: 0px 1px 1px 0px rgb(0 0 0 / 25%);
  border-radius: 10px;

  .mainContent {
    height: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.modalContent {
  padding: $padding2;

  .item {
    padding-bottom: $padding2;
    display: flex;
    flex-direction: column;

    .label {
      padding-bottom: $padding1;
      flex-shrink: 0;
    }

    .form {
      flex: 1 1;
    }
  }

  .buttons {
    display: flex;
    justify-content: center;

    .cancelButton {
      margin-right: $padding1;
    }
  }
}
