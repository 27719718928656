@import 'src/global/constants.scss';

.genderOtherInput {
  width: calc(100% - 62px);
  position: absolute;
  left: 62px;
  bottom: 34px;
}

.ageDescriptions {
  width: calc(100% - 66px);
  position: absolute;
  left: 66px;
  top: 2px;

  .item {
    height: 32px;
    display: flex;
    align-items: center;

    .label {
      padding: 0px $padding1;
      flex-shrink: 0;
    }
  }
}

.insurerOtherDesc {
  width: calc(100% - 68px);
  position: absolute;
  left: 68px;
  top: calc(50% + 32px * 4 - 16px);
}

.roleNeedDescriptions {
  width: calc(100% - 60px);
  position: absolute;
  top: 18px;
  left: 60px;

  .item {
    height: 32px;
  }
}

.deviceNeedDescriptions {
  width: calc(100% - 60px);
  position: absolute;
  top: 2px;
  left: 60px;

  .item {
    height: 32px;
  }
}
