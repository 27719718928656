@import 'src/global/constants.scss';

.container {
  margin-left: -$padding6;
  margin-right: -$padding6;
  padding-bottom: $padding3;
}

.omit {
  // width: 100%;
  // white-space: nowrap;
  // text-overflow: ellipsis;
  // overflow: hidden;

  white-space: break-spaces;
}
