@import 'src/global/constants.scss';

.container {
  padding: 0px $padding6;

  .title {
    padding-bottom: $padding2;
    font-size: 16px;
  }
}

.step3 {
  display: flex;
  justify-content: center;
  align-content: center;

  .buttonContainer {
    padding-right: $padding5;

    &:last-child {
      padding-right: 0px;
    }

    .button {
      width: 180px;
      display: flex;
      justify-content: center;
    }
  }
}

.step8 {
  padding-top: 22px;
  display: flex;
  justify-content: center;
  align-content: center;

  .buttonContainer {
    padding-right: 22px;

    &:last-child {
      padding-right: 0px;
    }

    .button {
      width: 200px;
      display: flex;
      justify-content: center;
    }
  }
}
