@import 'src/global/constants.scss';

#facet {
  .ant-collapse {
    background-color: transparent;
    border: none;
  }

  .ant-collapse-item,
  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-header {
    height: 56px;
    padding: 0px $padding2;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  .ant-collapse-expand-icon svg {
    font-size: 16px;
    transform: rotate(-90deg);
  }

  .ant-collapse-content-box {
    padding: 0px;
  }
}
