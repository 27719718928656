@import 'src/global/constants.scss';

#selfBarChart {
  .g2-tooltip {
    margin: 0px !important;
    padding: 5px 10px !important;
  }

  .g2-tooltip-title {
    margin: 0px !important;
    padding-bottom: 5px !important;
    font-size: 10px;
    color: #b3b3b3b3;
  }

  .g2-tooltip-list-item {
    margin: 0px !important;
    padding: 0px !important;
    font-size: 0px !important;
    line-height: normal !important;
  }

  .g2-tooltip-name,
  .g2-tooltip-marker {
    display: none !important;
  }

  .g2-tooltip-value {
    margin: 0px !important;
    float: none !important;
    font-size: 14px;
  }
}
