@import 'src/global/constants.scss';

.header {
  padding: $padding2 $padding6;
  background-color: $themeColor8;
  font-size: 32px;
  font-weight: 700;
  color: $basicColor1;
}

.content {
  padding: $padding3 $padding6;
}

.titleContainer {
  padding-bottom: $padding2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 24px;
    font-weight: 500;
  }
}

.closeButton {
  margin-right: $padding3;
}

.buttons {
  display: flex;
  justify-content: center;
}
