@import 'src/global/constants.scss';

.container {
  display: flex;
  justify-content: center;
}

.step8 {
  width: 422px;
  display: flex;
  justify-content: center;
}

.defaultButton {
  width: 110px;
  display: flex;
  justify-content: center;
}
