@import 'src/global/constants.scss';

#budgetDetailPageApplyModal {
  .ant-input-textarea {
    position: relative;
  }

  .ant-input-textarea::after {
    position: absolute;
    right: 0px;
    top: 100%;
  }
}
