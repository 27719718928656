@import 'src/global/constants.scss';

.container {
  padding: 0px $padding6 $padding3;
}

.padding {
  padding: $padding2;
}

.button {
  height: fit-content;

  .icon {
    color: $themeColor7;
  }
}

.labels {
  display: flex;
  background-color: $basicColor2;

  .item {
    flex: 1 1;
    padding: $padding2;
    font-size: 16px;
  }
}

.contents {
  display: flex;
  border-bottom: 1px solid $basicColor3;

  .item {
    flex: 1 1;
    padding: $padding2;
    display: flex;
    align-items: center;
    line-height: 24px;
  }
}
