@import 'src/global/constants.scss';

#caseDrawer {
  .ant-form-item {
    margin-bottom: 0px;
    padding-bottom: $padding1;
  }

  .ant-form-item-label {
    width: 140px;
    text-align: left;

    label {
      font-size: 16px;
    }
  }
}
