@import 'src/global/constants.scss';

.container {
  padding: $padding2;

  .label {
    padding-bottom: 8px;
    font-size: 16px;
  }

  .content {
    white-space: pre-wrap;
  }

  .formContainer {
    .inputContainer {
      padding-bottom: $padding2;
    }

    .buttonsContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cancelButton {
      margin-right: $padding3;
    }
  }
}
