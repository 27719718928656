@import 'src/global/constants.scss';

#loginPage {
  .ant-form-item-control-input {
    min-height: auto;
    height: fit-content;
  }

  .ant-form-item {
    margin-bottom: 28px;

    &:nth-last-child(2) {
      margin-bottom: 13px;
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  .ant-checkbox {
    top: 0px;
  }
}
