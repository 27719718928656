@import 'src/global/constants.scss';

.container {
  width: fit-content;
  position: relative;

  .iconContainer {
    transform: translateX(4px) translateY(6px);
    position: absolute;
    left: 100%;
    bottom: 100%;

    .icon {
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: $themeColor7;
    }
  }
}
