@import 'src/global/constants.scss';

.expand {
  width: 100%;
}

.container {
  padding: $padding2 $padding4;
  height: 100%;
  display: flex;
  flex-direction: column;

  .formContainer {
    flex: 1 1;
    padding-bottom: $padding2;
  }

  .buttons {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .saveButton {
      margin-bottom: $padding2;
    }
  }
}
