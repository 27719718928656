@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding3;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.searchContainer {
  padding: 0px $padding4 $padding1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .searchInput {
    width: 264px;
  }
}
