@import 'src/global/constants.scss';

.container {
  height: 100%;
  background-color: $basicColor3;
  display: flex;
  flex-direction: column;
}

.header {
  height: 64px;
  flex-shrink: 0;
  padding: 0 $padding3;
  background-color: $themeColor9;

  .title {
    font-size: 20px;
    font-weight: 600;
    line-height: 64px;
    color: $basicColor1;
  }
}

.mainContent {
  height: calc(100vh - 64px);
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logoContainer {
    margin-bottom: $padding4;
    flex-shrink: 0;
    width: 310px;

    .logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .formContainer {
    flex-shrink: 0;
    width: 360px;

    .prefixIcon {
      padding-right: $padding1;
      color: $themeColor7;
    }

    .rememberLabel {
      font-size: 14px;
    }

    .logoInButton {
      width: 100%;
      font-size: 16px;
      line-height: normal;
    }
  }

  .topSpace {
    flex: 1 1 210px;
  }

  .bottomSpace {
    flex: 1 1 380px;
  }
}
