@import 'src/global/constants.scss';

.mainContent {
  padding: $padding2 $padding4;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.createButtonContainer {
  flex-shrink: 0;
  padding-bottom: $padding2;
}

.tableContainer {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.operateButtons {
  flex-shrink: 0;
  padding-top: $padding2;
  display: flex;
  justify-content: center;

  .cancelButton {
    margin-right: $padding6;
  }
}
