@import 'src/global/constants.scss';

.container {
  margin-bottom: 20px;
  padding: $padding2 $padding4;
  border: 1px solid #dce0e6;

  &:last-child {
    margin-bottom: 0px;
  }

  .title {
    padding-bottom: $padding2;
    font-size: 16px;
  }

  .basicFractionContainer {
    padding-bottom: $padding2;
    display: flex;
    align-items: center;

    .label {
      padding-right: $padding1;
      font-size: 14px;
    }

    .formContainer {
      width: 50px;

      .input {
        text-align: center;
      }
    }
  }

  .barCharts {
    padding-bottom: 20px;
    display: flex;
    overflow-x: auto;

    .paddingRight {
      flex: 0 0;
      padding-right: $padding4;

      &:last-child {
        padding-right: 0px;
      }
    }
  }
}
