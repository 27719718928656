@import 'src/global/constants.scss';

.container {
  padding: 16px;
}

.mainContent {
  padding-bottom: $padding1;
  display: flex;
  align-items: center;

  .iconContainer {
    flex-shrink: 0;
    padding-right: $padding2;

    .icon {
      color: $warningColor1;
    }
  }
}

.buttons {
  display: flex;
  justify-content: center;

  .button:first-child {
    margin-right: $padding1;
  }
}
