@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding2;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.editContainer {
  flex: 0;
  padding: 0 $padding4 $padding2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .editButtons {
    display: flex;

    .space {
      width: 5px;
    }
  }
}

.searchContainer {
  display: flex;
  align-items: center;

  .inputContainer {
    width: 322px;
  }

  .selectContainer {
    padding-right: 20px;
    display: flex;
    align-items: center;

    .label {
      padding-right: 7px;
    }

    .searchSelect {
      width: 210px;
    }
  }
}
