@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding3;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.operateContainer {
  flex-shrink: 0;
  padding: 0 $padding4 $padding3;
  display: flex;
  justify-content: space-between;

  .select {
    margin-right: $padding2;
  }
}

.exportButton {
  padding: 0px;
}
