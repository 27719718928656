@import 'src/global/constants.scss';

.container {
  border: 1px solid $basicColor2;
  box-shadow: 0px 1px 1px 0px #00000040;
  border-radius: 10px;
  position: relative;
}

.tabContainer {
  padding: 0px $padding2;
}

.sumContainer {
  display: flex;
  position: absolute;
  right: 60px;
  top: 9px;

  .item {
    margin-right: $padding1;
    height: 38px;
    border: 1px dashed $basicColor5;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .lengthItem {
    padding: 0px 16px;
    min-width: 122px;
  }

  .implementWeightedItem,
  .satisWeightedItem {
    padding: 0px 6px;
    min-width: 200px;
  }
}
