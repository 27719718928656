@import 'src/global/constants.scss';

.group {
  display: flex;

  .label {
    flex-shrink: 0;
    padding: 12px 24px;
    width: 180px;
    background-color: #fafafa;
    border-top: 1px solid #f0f0f0;
    border-left: 1px solid #f0f0f0;
    display: flex;
    align-items: center;
  }

  .content {
    flex: 1 1;
  }
}

.socialSoftwareOtherDesc {
  width: calc(100% - 62px);
  position: absolute;
  left: 62px;
  bottom: 2px;
}

.dialOtherDesc {
  width: calc(100% - 62px);
  position: absolute;
  left: 62px;
  bottom: 2px;
}
