@import 'src/global/constants.scss';

#caseTable {
  .ant-tabs-nav {
    padding: 0px $padding6;

    &::before {
      margin: 0px $padding6;
    }
  }
}
