@import 'src/global/constants.scss';

#budgetDetailPage {
  .allowances {
    .ant-form-item {
      margin: 0px;
      padding: 0px $padding3 12px;
    }

    .ant-form-item-label {
      flex-shrink: 0;
      flex-basis: auto;
      max-width: none;
      min-height: auto;
      width: 200px;
    }

    .ant-form-item-control-input {
      min-height: auto;
    }

    .ant-form-item-required {
      height: auto;
      font-size: 16px;
      color: $basicColor13;
    }
  }

  .income {
    .ant-form-item {
      margin-bottom: 0px;
      position: relative;
    }

    .ant-form-item-explain {
      transform: translateY(-4px);
      position: absolute;
      top: 100%;
    }
  }
}
