@import 'src/global/constants.scss';

#customDrawer {
  .ant-drawer {
    position: fixed;
  }

  .ant-drawer-header {
    padding: $padding2 $padding4;
    border-bottom: 1px solid #dce0e6;

    .ant-drawer-title {
      order: 1;
      font-size: 20px;
      font-weight: normal;
    }

    .ant-drawer-close {
      order: 2;
    }
  }

  .ant-drawer-body {
    padding: 0px;
    overflow: hidden;
  }

  .ant-drawer-close {
    margin: 0px;
  }
}
