@import 'src/global/constants.scss';

.label {
  display: flex;
  align-items: center;

  .text {
    padding-right: $padding2;
  }

  .budgetTag {
    height: 32px;
    border-color: transparent;
    background-color: $basicColor3;
    display: flex;
    align-items: center;

    .icon {
      font-size: 14px;

      &.red {
        color: $themeColor7;
      }

      &.green {
        color: $successColor1;
      }
    }
  }
}
