@import 'src/global/constants.scss';

.modal {
  border-radius: 10px !important;
}

.label {
  padding-bottom: $padding1;
  font-size: 16px;

  .star {
    color: $themeColor7;
  }

  .colon {
    margin-left: 2px;
  }
}
