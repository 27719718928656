@import 'src/global/constants.scss';

// 共用
.title {
  flex-shrink: 0;
  padding-bottom: $padding2;
  font-size: 16px;
}

// 共用
.expand {
  margin: 0px !important;
  width: 100%;
}

.select {
  & [title='請選擇'] {
    color: rgba(37, 37, 37, 0.25);
  }
}

.container {
  padding: $padding2 $padding4 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

// 上半部篩選
.selectsContainer {
  flex-shrink: 0;
  padding-bottom: $padding2;

  .formContainer {
    padding-bottom: $padding1;
    display: flex;

    // 上方左右邊的表單
    .flexItem {
      flex: 1 1;

      .formItem {
        padding-bottom: $padding1;
        display: flex;
        align-items: center;

        .label {
          flex-shrink: 0;
          width: 100px;
          font-size: 16px;
        }
      }
    }

    //  空白
    .space {
      flex-shrink: 0;
      width: $padding6;
    }
  }

  .filterButton {
    width: 100%;
    display: block;
    align-items: center;
  }
}

// 下半部表格
.resContainer {
  flex: 0 0;
  display: flex;
  flex-direction: column;

  .resDesc {
    flex-shrink: 0;
    padding-bottom: $padding1;
  }
}

.operateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  transform: translateX(-50%);
  position: absolute;
  left: 50%;
  bottom: 20px;

  .label {
    padding-bottom: $padding1;
  }

  .space {
    width: 10px;
    height: 32px;
  }

  .button {
    width: 405px;
  }
}
