@import 'src/global/constants.scss';

.header {
  padding-top: $padding2;

  .title {
    padding: 0 $padding2;
    font-size: 20px;
  }
}

.divider {
  padding: $padding2 0;

  .line {
    border-bottom: 1px solid #dce0e6;
  }
}

.contentPadding {
  padding: 0px $padding5;
}

.footer {
  padding: $padding3 0;
  display: flex;
  justify-content: center;

  .buttonRightMargin {
    margin-right: $padding5;
  }
}
