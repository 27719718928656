@import 'src/global/constants.scss';

.container {
  margin-bottom: $padding2;
  padding: $padding2 $padding3;
  background-color: $basicColor2;
  border: 1px solid transparent;

  &.invalidBorder {
    border: 1px solid $themeColor7;
  }
}

.title {
  padding-bottom: $padding1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .deleteButton {
    padding: 0px;
    height: fit-content;
    font-size: 24px;
  }
}

.item {
  padding-bottom: $padding1;
  display: flex;
  align-items: center;

  .label {
    flex-shrink: 0;
    padding-right: $padding1;
  }
}
