@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding2;
  height: 100%;
  display: flex;
  flex-direction: column;

  .mainContent {
    flex: 1;
    padding: 0px $padding4;
    overflow-y: auto;
  }

  .formContainer {
    .overflowX {
      overflow-x: auto;
    }
  }
}

// 儲存按鈕
.operateContainer {
  flex-shrink: 0;
  padding: 0px $padding4;
  display: flex;
  justify-content: flex-end;

  .buttonContainer {
    margin-right: $padding2;

    &:last-child {
      margin-right: 0px;
    }
  }
}

// 專案件酬試算
.allowancesContainer {
  // padding-bottom: $padding1;
  // min-height: 440px;

  .title {
    padding-bottom: $padding2;
    font-size: 16px;
  }

  .overflowX {
    margin-left: -$padding4;
    margin-right: -$padding4;
    overflow-x: auto;
  }

  .allowancesContent {
    padding: 0px $padding4;
    display: flex;
    min-width: 1580px;

    // 項目
    .itemsContainer {
      margin-left: -$padding3;
      flex: 1 1;
      display: flex;

      .items {
        flex: 1 1;
      }

      // 新增動態酬勞的按鈕
      .addDynamicItemButtonContainer {
        padding: 0px $padding3;

        .button {
          padding: 0px;
        }
      }
    }

    // 結果
    .resContainer {
      flex-shrink: 0;
      display: flex;

      // 分隔線
      .divide {
        margin: 0px $padding1;
        width: 1px;
        height: 100%;
        background-color: $basicColor4;
      }

      // 計算結果垂直容器
      .resContainer {
        padding-left: $padding3;
        display: flex;
        flex-direction: column;

        .resTable {
          width: 350px;
          display: flex;
          flex-direction: column;

          .labels,
          .contents {
            display: flex;
          }

          .label,
          .content {
            flex: 1 1;
            padding: $padding2 $padding1;
            display: flex;
            align-items: center;
          }

          .labels {
            background-color: $basicColor2;
          }

          .contents {
            border-bottom: 1px solid $basicColor3;
          }
        }
      }
    }
  }
}

// 專案損益表
.incomeContainer {
  .title {
    padding-bottom: $padding2;
    font-size: 16px;
  }

  // 結果表格
  .resTableContainer {
    padding-bottom: $padding2;

    .labels,
    .contents {
      display: flex;
    }

    .label,
    .content {
      flex: 1 1;
      padding: $padding2;
      display: flex;
      align-items: center;
    }

    .labels {
      background-color: $basicColor2;
      display: flex;
      align-items: center;

      .text {
        padding-right: 6px;
      }

      .icon {
        font-size: 16px;
      }
    }

    .contents {
      border-bottom: 1px solid $basicColor3;
    }
  }

  // 詳細項目
  .detailTableRow {
  }
}
