@import 'src/global/constants.scss';

.container {
  flex: 1 1;
  display: flex;
  align-content: center;
  position: relative;

  &::before {
    width: 100%;
    height: 100%;
    content: '';
    display: block;
    border: 1px solid transparent;
    position: absolute;
    left: 0px;
    top: 0px;
  }

  &.offsetBorderLeft::before {
    width: calc(100% + 1px);
    left: -1px;
  }

  .labelContainer {
    padding: 12px 24px;
    background-color: $basicColor2;
    border-top: 1px solid $basicColor4;
    border-left: 1px solid $basicColor4;
    display: flex;
    align-items: center;

    &.small {
      width: 120px;
    }

    &.middle {
      width: 180px;
    }

    .icon {
      margin-right: 4px;
      width: 10px;
      height: 10px;

      &.saIcon {
        background-color: $saColor1;
      }

      &.pmIcon {
        background-color: $pmColor1;
      }

      &.omIcon {
        background-color: $omColor1;
      }
    }

    .required {
      color: $themeColor7;
    }

    .label {
      padding-left: 2px;
      position: relative;
    }
  }

  .contentContainer {
    padding: 12px 24px;
    border-top: 1px solid $basicColor4;
    border-left: 1px solid $basicColor4;
    flex: 1 1;

    .content {
      height: 100%;
      display: flex;
      align-items: center;
      position: relative;
    }
  }
}
