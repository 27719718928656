@import 'src/global/constants.scss';

.container {
  padding: $padding3 $padding4 $padding3;
  width: 330px;

  .detail {
    padding-bottom: $padding2;
  }

  .button {
    width: 100%;
    height: 50px;
  }

  .logOutText {
    font-weight: $fontWeight7;
  }
}
