@import 'src/global/constants.scss';

.mainContent {
  height: 100%;
  overflow-y: auto;
}

.mainOperate {
  padding: $padding2 $padding4;
  display: flex;
  justify-content: flex-end;
}

.formContainer {
  padding: 0px $padding4 $padding2;

  // 有禁用按鈕的項目
  .disabledItem {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    & > div:first-child {
      flex: 1 1;
      margin-bottom: 0px !important;
      max-width: none;

      // label container
      & > div:first-child > div:first-child {
        flex: 0 0 auto;
        max-width: none;
        width: 193.33px;
      }
    }

    .buttonContainer {
      padding-left: $padding2;
    }

    .successButton {
      background-color: $successColor1;
    }
  }
}

.windowsContainer {
  padding: 0px $padding4 $padding2;
  display: flex;
  flex-direction: column;

  .header {
    flex-shrink: 0;
    padding-bottom: $padding1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .label {
      font-size: 16px;
      color: rgba(37, 37, 37, 0.88);
    }
  }
}
