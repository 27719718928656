@import 'src/global/constants.scss';

.link {
  color: $basicColor13;
  white-space: pre-wrap;
}

.content {
  white-space: pre-wrap;
}
