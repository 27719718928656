@import 'src/global/constants.scss';

.emergencyContact {
  padding-bottom: $padding1;
  font-size: 16px;
}

.expanded {
  width: 100%;
}
