@import 'src/global/constants.scss';

.item {
  height: 32px;
}

.shoppingNeedsCommodity {
  width: calc(100% - 124px - 2px);
  position: absolute;
  left: 124px;
  top: 18px;
}

.shoppingNeedsSpend {
  width: calc(100% - 94px - 2px);
  position: absolute;
  left: 94px;
  top: 50px;
}

.mediaRecordNeedsOtherDesc {
  width: calc(100% - 62px - 2px);
  position: absolute;
  left: 62px;
  top: 98px;
}

.paymentMethodsDesc {
  width: calc(100% - 62px);
  position: absolute;
  left: 62px;
  bottom: 2px;
}

.uniformNumber {
  width: calc(100% - 24px);
  position: absolute;
  left: 24px;
  top: 34px;
}

.checkDatesDescSame {
  width: calc(100% - 94px);
  position: absolute;
  left: 94px;
  top: 2px;
}

.checkDatesDescAlone {
  width: calc(100% - 116px);
  position: absolute;
  left: 116px;
  top: 130px;
}

.inspectedPersonNeedDesc {
  width: calc(100% - 150px);
  position: absolute;
  left: 150px;
  top: 66px;
}

.complaintRequirementsDesc {
  width: calc(100% - 62px);
  position: absolute;
  left: 62px;
  top: 0px;
}

.complainantRequirementsDesc {
  width: calc(100% - 62px);
  position: absolute;
  left: 62px;
  top: 18px;
}

.claimsDmDesc {
  width: calc(100% - 90px);
  position: absolute;
  left: 90px;
  top: calc(50% - 64px);
}

.claimsDescSame {
  width: calc(100% - 64px);
  position: absolute;
  left: 64px;
  top: calc(50% - 32px);
}
