@import 'src/global/constants.scss';

.container {
  display: flex;
  border-bottom: 1px solid $basicColor4;

  .tableContent {
    flex: 1 1;
    padding: 0px $padding2;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.deleteButton {
  font-size: 20px;
  display: flex;
  align-items: center;
  border: none;
}
