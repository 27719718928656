// 基本顏色, 黑白
$basicColor1: #ffffff;
$basicColor2: #fafafa;
$basicColor3: #f5f5f5;
$basicColor4: #f0f0f0;
$basicColor5: #d9d9d9;
$basicColor6: #bfbfbf;
$basicColor7: #8c8c8c;
$basicColor8: #595959;
$basicColor9: #434343;
$basicColor10: #262626;
$basicColor11: #1f1f1f;
$basicColor12: #141414;
$basicColor13: #000000;

// 主題顏色
$themeColor1: #fff1f0;
$themeColor2: #ffccc7;
$themeColor3: #ffa39e;
$themeColor4: #ff7875;
$themeColor5: #ff4d4f;
$themeColor6: #f5222d;
$themeColor7: #cf1322;
$themeColor8: #a8071a;
$themeColor9: #820014;
$themeColor10: #5c0011;

// 綠色
$successColor1: #52c41a;

// 橘色
$warningColor1: #ffa629;

// 角色的顏色
$saColor1: #3c9ae8;
$pmColor1: #FFC670;
$omColor1: #52c41a;

// 邊距
$padding1: 10px;
$padding2: 20px;
$padding3: 30px;
$padding4: 40px;
$padding5: 50px;
$padding6: 60px;

// 文字粗細
$fontWeight1: 100;
$fontWeight2: 200;
$fontWeight3: 300;
$fontWeight4: 400;
$fontWeight5: 500;
$fontWeight6: 600;
$fontWeight7: 700;
$fontWeight8: 800;
$fontWeight9: 900;
