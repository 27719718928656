@import 'src/global/constants.scss';

.simpleInputNumber {
  .ant-input-number {
    width: 100%;
  }

  .ant-input-number-disabled input {
    color: rgba(37, 37, 37, 0.25);
  }
}
