@import 'src/global/constants.scss';

.formLink {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;

  .linkButton {
    padding-left: 10px;
  }

  .icon {
    font-size: 24px;
  }
}

.link {
  word-break: break-all;
}
