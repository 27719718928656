@import 'src/global/constants.scss';

.fixedTable {
  .padding {
    .ant-table-header,
    .ant-table-body {
      padding: 0px 40px;
    }
  }
}
