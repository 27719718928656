@import 'src/global/constants.scss';

.container {
  overflow-x: hidden;
}

.content {
  padding: $padding4 0px;
}

.paddingBottom {
  padding-bottom: $padding3;
}
