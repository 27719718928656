@import 'src/global/constants.scss';

.container {
  padding: $padding3 $padding4;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header {
    flex-shrink: 0;
    padding-bottom: $padding1;
    font-size: 16px;
  }

  .paginationContainer {
    flex-shrink: 0;
    padding-top: $padding2;
    display: flex;
    justify-content: center;
  }
}

.tableContainer {
  margin-left: -$padding4;
  margin-right: -$padding4;
  // 60px header
  // 45px breadcrumb
  // 60px back link title
  // 49px tab title
  // 35px id
  // 52px paging
  // $padding3 container padding
  max-height: calc(
    100vh - 60px - 45px - 60px - 49px - 35px - 52px - $padding3 - $padding3
  );
  overflow-y: auto;
}
