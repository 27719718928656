@import 'src/global/constants.scss';

.formItem {
  width: 100%;
}

.label {
  padding: 0px 7px;
  white-space: pre-wrap;
  border: 1px solid transparent;
}
