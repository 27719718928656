@import 'src/global/constants.scss';

.container {
  padding: $padding2;
}

.content {
  padding-bottom: $padding1;
  display: flex;
  align-items: center;

  .icon {
    padding-right: $padding2;
    color: $warningColor1;
  }
}

.imageContainer {
  padding-bottom: $padding1;
  width: 100%;

  .image {
    width: 100%;
    object-fit: contain;
  }
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;

  .button:first-child {
    margin-right: $padding2;
  }
}
