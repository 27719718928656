@import 'src/global/constants.scss';

.mainContent {
  padding-bottom: $padding2;
}

.label {
  padding-bottom: $padding1;
  font-size: 16px;

  .star {
    color: $themeColor7;
  }

  .colon {
    margin-left: 2px;
  }
}

.content {
  white-space: pre-wrap;
}
