@import 'src/global/constants.scss';

.checkItemFacet {
  .ant-collapse {
    background-color: transparent;
    border: none;
  }

  .ant-collapse-item,
  .ant-collapse-content {
    border: none;
  }

  .ant-collapse-header {
    height: 56px !important;
    padding: 0px $padding2 !important;
    display: flex !important;
    flex-direction: row-reverse !important;
    align-items: center !important;
    position: relative !important;
    z-index: 1 !important;
  }

  .ant-collapse-expand-icon svg {
    font-size: 16px;
    transform: rotate(-90deg);
  }

  .ant-collapse-content-box {
    padding: 0px;
  }
}
