@import 'src/global/constants.scss';

// 平均總分
.container {
  flex-shrink: 0;
  display: flex;
  overflow-x: auto;

  .mainTotal {
    flex-shrink: 0;
    margin-right: $padding2;
    padding: 18px 0px;
    width: 200px;
    background-color: #e4e6e7;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .paddingBottom {
      padding-bottom: 12px;
    }

    .totalContainer {
      padding: 0px 24px;
    }

    .item {
      padding: 0px 20px;
      display: flex;
      align-items: center;

      .label {
        padding-right: 8px;
        flex-shrink: 0;

        .star {
          color: $themeColor7;
        }
      }

      .inputContainer {
        display: flex;
        align-items: center;

        .ratio {
          padding-left: 4px;
        }
      }
    }

    .buttonContainer {
      padding: 0px 24px;
      width: 100%;
      display: flex;
      justify-content: center;

      .button {
        padding: 0px 18px;
        width: 100%;
        background-color: #ff7875;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .icon,
        .content {
          color: #ffffff;
        }
      }
    }
  }

  // 平均落實度, 平均滿意度
  .typesTotal {
    margin-right: 20px;
    padding: 20px 24px;
    width: 200px;
    background-color: #e4e6e7;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .paddingBottom {
      padding-bottom: 12px;
    }
  }

  // 構面平均落實度
  .facetsTotal {
    padding: 18px 24px;
    background-color: #e4e6e7;
    border-radius: 2px;
    background-image: url('../../../../assets/client-facets-total-background.svg');
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      padding-bottom: 12px;
      font-size: 15px;
      font-weight: 700;
    }

    .items {
      display: flex;

      .label {
        padding-right: 14px;
        writing-mode: vertical-lr;
        text-align: center;
      }
    }

    .paddingRight {
      padding-right: 15px;

      &:last-child {
        padding-right: 0px;
      }
    }

    .divider {
      margin: 12px 0px;
      border-color: #00000040;
    }
  }
}
