@import 'src/global/constants.scss';

.container {
  .example {
    padding-bottom: $padding2;
    display: flex;
    justify-content: center;
  }

  .title {
    font-size: 16px;
  }

  .subtitle {
    font-size: 16px;
  }
}
