@import 'src/global/constants.scss';

.middle {
  padding: 15px 0px 20px;
  width: 142px;
  background-color: #ffffff;
  border-radius: 32px;
  box-shadow: 0px 4px 20px 0px #b6c5cd4d;

  .label {
    padding-bottom: 5px;
    font-size: 14px;
    text-align: center;
  }

  .content {
    text-align: center;

    .fraction {
      padding-right: 10px;
      font-size: 24px;
      font-weight: 700;
    }

    .unit {
      font-size: 16px;
    }
  }
}

.small {
  padding: 8px 0px;
  width: 108px;
  background-color: #ffffff;
  border-radius: 22px;
  box-shadow: 0px 4px 20px 0px #b6c5cd4d;

  .label {
    padding-bottom: 4px;
    font-size: 13px;
    text-align: center;
  }

  .content {
    text-align: center;

    .fraction {
      padding-right: 6px;
      font-size: 20px;
      font-weight: 700;
    }

    .unit {
      font-size: 14px;
    }
  }
}
