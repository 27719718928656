@import 'src/global/constants.scss';

.formItem {
  max-width: 100%;
  width: 300px;
}

.label {
  padding: 0px 7px;
  min-height: 32px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
}
