@import 'src/global/constants.scss';

.container {
}

.formSection {
  padding-bottom: $padding3;

  .title {
    padding-bottom: $padding1;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
  }
}

.icon {
  margin-right: 4px;
  width: 10px;
  height: 10px;

  &.saIcon {
    background-color: $saColor1;
  }

  &.pmIcon {
    background-color: $pmColor1;
  }

  &.omIcon {
    background-color: $omColor1;
  }
}
