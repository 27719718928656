@import 'src/global/constants.scss';

#customForm {
  .ant-form > .ant-form-item {
    margin-bottom: $padding1;
  }

  .ant-form > .ant-form-item:last-child {
    margin-bottom: 0px;
  }

  label {
    font-size: 14px;
  }

  // 總規格表使用
  .ant-form-item {
    margin-bottom: 0px;
  }

  .ant-checkbox-group {
    display: block;

    .ant-checkbox-wrapper {
      height: 32px;
      margin-inline-start: 0px;
      font-size: 14px;
      display: flex;
      align-items: center;

      &:last-child {
        padding-bottom: 0px;
      }
    }

    .ant-checkbox {
      transform: translateY(-2.5px);
    }
  }

  .ant-radio-wrapper {
    height: 32px;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  .ant-radio-group,
  .ant-checkbox-group {
    padding: 1px;
    border: 1px solid transparent;
  }

  // ant form 錯誤提示
  .ant-form-item-explain-error {
    display: none;
  }

  // date picker error border
  .ant-picker-status-error {
    border-color: #d9d9d9;
  }

  // date picker focused border
  .ant-picker-focused {
    border-color: #db373f;
  }

  // 總規則表
  .formatCell:has(.ant-form-item-has-error)::before {
    border-color: #ff4d4f;
  }

  // 表單 disabled 時, 文字顏色, 暫時不調整
  .ant-radio-wrapper-disabled {
    color: rgba(37, 37, 37, 0.25);
  }
}
