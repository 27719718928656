@import 'src/global/constants.scss';

.container {
  margin-bottom: $padding2;
  padding: $padding2 $padding3;
  background-color: $basicColor2;
  border: 1px solid transparent;

  .title {
    padding-bottom: $padding1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

// 加分題
.bonusContainer {
  padding-bottom: $padding1;
  display: flex;
  align-items: center;

  .label {
    padding-left: $padding1;
  }
}

.textContainer {
  padding-bottom: $padding1;
  display: flex;
  align-items: start;

  .label {
    flex-shrink: 0;
    padding-right: $padding1;
  }

  .content {
    flex: 1 1;
    white-space: pre-wrap;
  }
}

.fractionContainer {
  padding-bottom: $padding1;
  display: flex;
  align-items: center;

  .icon {
    margin-right: $padding1;
    flex-shrink: 0;
    color: $themeColor7;
  }

  .label {
    flex-shrink: 0;
    padding-right: $padding1;
  }
}

.descContainer {
  display: flex;
  align-items: start;

  .label {
    padding-right: $padding1;
    flex-shrink: 0;
  }

  .content {
    flex: 1 1;
    white-space: pre-wrap;
  }
}
