@import 'src/global/constants.scss';

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.tabContainer {
  flex: 1;
}
