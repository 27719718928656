@import 'src/global/constants.scss';

#interviewerCreateModal {
  .ant-modal-content {
    border-radius: 10px;
  }

  .customModalFooter {
    padding-top: 70px;
  }

  label {
    font-size: 16px;
  }
}
