@import 'src/global/constants.scss';

.header {
  padding: 0px $padding2;
  height: 60px;
  background-color: $basicColor9;
  display: flex;
  align-items: center;

  .backButton {
    display: flex;
    align-items: center;
    border-color: transparent;
  }

  .link {
    padding-right: $padding2;
    color: $basicColor1;
  }

  .icon {
    font-size: 16px;
    color: $basicColor1;
    // transform: translateY(1px);
  }

  .title {
    font-size: 20px;
    font-weight: 500;
    color: $basicColor1;
  }
}

.paddingBottom {
  padding-bottom: $padding2;
}
