#customModal {
  padding: 0;

  .ant-modal-close {
    top: 34px;
  }

  .ant-modal {
    background-color: #ffffff;
    border-radius: 2px;
  }

  .ant-modal-content {
    padding: 0px;
    background-color: transparent;
    border-radius: 0px;
  }
}
