@import 'src/global/constants.scss';

.container {
  &.flex {
    flex: 1 1;
  }

  &.hidden {
    overflow: hidden;
  }
}
