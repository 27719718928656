@import 'src/global/constants.scss';

.container {
  padding: 0px $padding6 $padding3;

  .title {
    padding-bottom: $padding2;
    font-size: 16px;
  }

  & .styleItem:last-child {
    margin-bottom: 0px;
  }
}
