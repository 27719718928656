@import 'src/global/constants.scss';

.container {
  .mainContent {
    border-right: 1px solid $basicColor4;
    border-bottom: 1px solid $basicColor4;
  }
}

.row {
  display: flex;

  .bItem {
    flex: 3 3;
    display: flex;
  }

  .sItem {
    flex: 2 2;
    display: flex;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .deleteButton {
    display: flex;
    align-items: center;
    border: none;
  }

  .icon {
    padding-right: $padding1;
    font-size: 18px;
  }
}

.desc {
  position: absolute;

  // 查檢表
  &.checkFormDesc {
    width: calc(100% - 78px);
    left: 78px;
    top: 2px;
  }

  // 稽查原始報告
  &.originalReportDesc {
    width: calc(100% - 110px);
    display: flex;
    align-items: center;
    top: 34px;
    left: 110px;

    .label {
      flex-shrink: 0;
      padding-right: $padding1;

      &.disabled {
        color: rgba(37, 37, 37, 0.25);
      }
    }
  }

  // 統計
  &.statisticsFileDesc {
    width: calc(100% - 66px);
    left: 66px;
    top: 66px;
  }

  // 分析報告
  &.analysisReportDesc {
    width: calc(100% - 94px);
    left: 94px;
    top: 98px;
  }

  // 簡報
  &.powerPointFileDesc {
    width: calc(100% - 66px);
    left: 66px;
    top: 130px;
  }

  // 照片
  &.photosFileDesc {
    width: calc(100% - 66px);
    left: 66px;
    top: 162px;
  }

  // 影片
  &.videoFileDesc {
    width: calc(100% - 66px);
    left: 66px;
    top: 194px;
  }

  // 後製影片檔
  &.postProductionVideoFileDesc {
    width: calc(100% - 110px);
    left: 110px;
    top: 226px;
  }

  // 錄音
  &.recordingFileDesc {
    width: calc(100% - 66px);
    left: 66px;
    top: 258px;
  }

  // 後製錄音檔
  &.postProductionRecordingFileDesc {
    width: calc(100% - 110px);
    left: 110px;
    top: 290px;
  }

  // 文件物品
  &.thingFileDesc {
    width: calc(100% - 94px);
    left: 94px;
    top: 322px;
  }

  // 商品
  &.commodityDesc {
    width: calc(100% - 66px);
    left: 66px;
    bottom: 34px;
  }

  // 其他
  &.otherDesc {
    width: calc(100% - 66px);
    left: 66px;
    bottom: 2px;
  }
}

.timesTable {
  flex: 1 1;

  .row {
    border-bottom: 1px solid $basicColor4;
  }

  .row:nth-last-child(2) {
    margin-bottom: $padding1;
  }

  .tableTitle,
  .tableContent {
    padding: $padding1 $padding2;

    display: flex;
    align-items: center;
  }

  .tableTitle {
    height: 60px;
    background-color: $basicColor2;
  }

  .tableContent {
    min-height: 60px;
  }

  .deleteIcon {
    font-size: 20px;
  }

  .select {
    width: 100%;
  }

  .addButtonContainer {
  }

  .deleteHandOverItemDateButton {
    border: none;
    display: flex;
    align-items: center;
  }
}
