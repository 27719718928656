@import 'src/global/constants.scss';

#caseEndDateTag {
  .ant-tag {
    margin: 0px;
    padding: 0px;
    border: none;
    background-color: transparent;
  }
}
