@import 'src/global/constants.scss';

.textContainer {
  // padding: 1px 8px;
}

.operate {
  display: flex;
  justify-content: center;
  align-items: center;

  .deleteButton {
    padding: 0px;
    height: fit-content;
  }

  .deleteIcon {
    font-size: 14px;
  }
}
