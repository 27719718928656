@import 'src/global/constants.scss';

.drawerContent {
  padding: $padding2 $padding4;
  height: 100%;
  display: flex;
  flex-direction: column;

  .searchContainer {
    padding-bottom: $padding4;
    flex-shrink: 0;
    display: flex;
    align-items: center;

    .label {
      flex-shrink: 0;
      padding-right: $padding2;
    }

    .input {
      width: 260px;
    }
  }

  .tableLabel {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .resContainer {
    padding-top: $padding2;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    .label {
      padding-bottom: $padding1;
      height: 26px;
    }

    .saveButton {
      width: 400px;
    }
  }
}
