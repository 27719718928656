@import 'src/global/constants.scss';

.container {
  .waveDetailContainer {
    padding-bottom: $padding2;

    &:last-child {
      padding-bottom: 0px;
    }
  }

  .addButtonContainer {
    padding-top: 0px;
  }
}
