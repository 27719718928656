@import 'src/global/constants.scss';

.container {
  padding: 0px $padding3 12px;
  display: flex;
  align-items: center;

  .label {
    flex-shrink: 0;
    width: 200px;

    .colon {
      padding-left: 2px;
    }
  }

  .content {
    flex: 1 1;
  }

  .input {
    padding: 0px $padding1;
    width: 100%;
  }
}
