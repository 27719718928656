@import 'src/global/constants.scss';

.input {
  text-align: center;
}

.container {
  padding-bottom: $padding2;
  height: 100%;
  display: flex;
  flex-direction: column;
}

// 沒有資料
.emptyDataContainer {
  flex: 1 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selectContainer {
  flex-shrink: 0;
  padding: 0px $padding4 12px;
  display: flex;
  align-items: center;

  .label {
    padding-right: $padding2;
    display: flex;
    align-items: center;

    .star {
      color: $themeColor7;
    }
  }
}
