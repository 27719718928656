@import 'src/global/constants.scss';

.container {
  margin-left: -$padding6;
  margin-right: -$padding6;
}

.lineHeight {
  line-height: 1.5;
}
