@import 'src/global/constants.scss';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .svgContainer {
    margin-bottom: 8px;
    height: 40px;
  }

  .label {
    font-size: 14px;
    text-align: center;
    color: rgba(37, 37, 37, 0.25);
  }
}
