@import 'src/global/constants.scss';

.container {
  width: 350px;

  .chartContainer {
    height: 300px;
  }

  .date {
    padding-top: 10px;
    font-size: 14px;
    text-align: center;
  }
}
