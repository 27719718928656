@import 'src/global/constants.scss';

.operateButtons {
  padding: 0px $padding6 $padding1;
  display: flex;
  justify-content: space-between;

  .createButtons {
    display: flex;

    .space {
      width: $padding1;
    }
  }
}

.tableContainer {
  .interviewerContainer {
    display: flex;
    align-items: center;

    .interviewer {
      padding-right: $padding1;
    }
  }

  .endDateContainer {
    display: flex;
    align-items: center;

    .date {
      padding-right: $padding1;
      border: none;
    }

    .upcoming {
      color: $warningColor1;
    }
  }

  .operateContainer {
    display: flex;
    align-items: center;

    .link {
      padding-right: 8px;
      flex-shrink: 0;
    }
  }

  .directions {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.emptyDataContainer {
  padding-bottom: $padding2;
}
