@import 'src/global/constants.scss';

.container {
  padding: $padding2 $padding2 0;
  display: flex;
  align-items: center;
  background-color: $basicColor9;

  .link,
  .pageSpan {
    font-size: 16px !important;
    font-weight: $fontWeight5 !important;
    color: $basicColor1 !important;
  }
}
