@import 'src/global/constants.scss';

.container {
  padding: 0px $padding4;
  display: flex;
  flex-direction: column;
}

.operate {
  padding-bottom: $padding2;
}

.table {
  height: calc(100vh - 60px - 80px - 44px - 46px - 16px - 52px - 20px);
  display: flex;
  flex-direction: column;
}

.operate {
  display: flex;
  column-gap: 10px;

  .button {
    padding: 4px;
  }
}
