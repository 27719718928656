@import 'src/global/constants.scss';

.marginRight {
  margin-right: $padding2;
}

.title {
  padding-bottom: $padding2;
  font-size: 16px;
}

.reason {
  max-width: 320px;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.operateButtons {
  display: flex;
}

.rejectButton {
  color: $basicColor1;
  background-color: $basicColor13;

  &:hover {
    color: $basicColor1 !important;
    background-color: $basicColor9;
  }
}
