@import 'src/global/constants.scss';

#staffEditModal {
  .ant-modal-content {
    border-radius: 10px;
  }

  label {
    font-size: 16px;
  }
}
