@import 'src/global/constants.scss';

.hide {
  display: none;
}

.container {
  padding-top: $padding2;
  height: calc(100vh - 60px - 45px - 60px - 50px);

  .mainContent {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .header {
    flex-shrink: 0;
    padding: 0px 70px $padding1;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      font-size: 16px;
    }
  }

  .formContainer {
    width: 100%;
    flex: 1 1;
    overflow: auto;

    .minWidth {
      padding: 0px 70px $padding2;
      min-width: 1180px;
    }

    .borderRightBottom {
      border-right: 1px solid rgba(5, 5, 5, 0.06);
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    }
  }
}

.section {
  display: flex;

  .titleContainer {
    width: 150px;
    background-color: rgba(37, 37, 37, 0.02);
    border: 1px solid rgba(5, 5, 5, 0.06);
    border-bottom: none;
    border-right-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  .descriptionContainer {
    flex: 1 1;
  }
}

// 每行
.row {
  flex: 1 1;
  display: flex;

  // 每項
  .item {
    flex: 1 1;
    display: flex;

    &.flex2 {
      flex-grow: 2;
    }

    &.flex3 {
      flex-grow: 3;
    }

    // 項目標題
    .label {
      flex-shrink: 0;
      padding: 10px 24px;
      border-top: 1px solid rgba(5, 5, 5, 0.06);
      border-left: 1px solid rgba(5, 5, 5, 0.06);
      background-color: rgba(37, 37, 37, 0.02);
      text-align: left;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      word-break: break-all;

      &.labelWidth1 {
        min-width: 150px;
      }

      &.labelWidth2 {
        width: 150px;
      }
    }

    // 項目內容
    .content {
      flex: 1 1;
      padding: 24px 18px;
      min-height: 81px;
      border-top: 1px solid rgba(5, 5, 5, 0.06);
      border-left: 1px solid rgba(5, 5, 5, 0.06);
      word-break: break-all;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      position: relative;

      .tag {
        margin: 8px 8px 8px 0px;
      }

      .contentLabel {
        padding: 0px 7px;
        border: 1px solid transparent;
      }
    }
  }
}
