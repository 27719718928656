@import 'src/global/constants.scss';

.item {
  margin-bottom: 15px;
  border: 1px solid $basicColor2;
  box-shadow: 0px 1px 1px 0px #00000040;
  border-radius: 10px;
}

.content {
  padding: 10px $padding3;
  min-height: 58px;
  white-space: break-spaces;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
