@import 'src/global/constants.scss';

.container {
  padding: 0px $padding3 12px;
  display: flex;
  align-items: center;
  position: relative;

  .label {
    flex-shrink: 0;
    padding-right: $padding2;
    width: 200px;
    display: flex;
    align-items: center;

    .star {
      color: $themeColor7;
    }

    .input {
      margin-right: calc($padding1 / 2);
      padding: 0px $padding1;
    }
  }

  .content {
    flex: 1 1;

    .input {
      padding: 0px $padding1;
    }
  }

  .deleteButtonContainer {
    position: absolute;
    left: calc(100% - $padding3);
  }
}
