@import 'src/global/constants.scss';

.container {
  margin-left: -$padding4;
  margin-right: -$padding4;
  padding-top: $padding1;
  padding-bottom: $padding1;
  min-width: 100%;
  overflow-x: auto;
}

.minWidth {
  padding: 0px $padding4;
  min-width: 1100px;
}

.totals {
  padding-bottom: $padding2;
  display: flex;
  justify-content: center;

  .item {
    margin-right: $padding2;
    padding: $padding1 $padding2;
    border: 1px dashed $themeColor7;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &:last-child {
      margin-right: 0px;
    }

    .row {
      font-size: 16px;
    }
  }
}

.itemContainer {
  padding-bottom: $padding1;
}
