@import 'src/global/constants.scss';

.desc {
  width: calc(100% - 64px);
  display: flex;
  align-items: center;
  position: absolute;
  left: 64px;
  top: 2px;

  .label {
    flex-shrink: 0;
    padding: 0px $padding1;
  }
}
