@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding3;
}

.header {
  padding-bottom: $padding2;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .title {
    font-size: 16px;
  }

  .rejectButton {
    display: flex;
    align-items: center;

    .icon {
      font-size: 11px;
    }
  }
}

.logs {
  margin-left: -$padding6;
  margin-right: -$padding6;
  padding-bottom: $padding2;
  overflow-x: auto;

  .paddingLeftRight {
    padding: 0px $padding6;
    width: fit-content;
    display: flex;
  }

  .log {
    flex-shrink: 0;
    width: 150px;

    .media {
      margin-bottom: 10px;
      width: 100%;
      height: 10px;
      position: relative;

      .circle {
        background-color: $themeColor7;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;

        &.large {
          width: 10px;
          height: 10px;
        }

        &.middle {
          width: 8px;
          height: 8px;
        }

        &.black {
          background-color: #000000;
        }
      }

      .line {
        width: 130px;
        height: 3px;
        background-color: $basicColor2;
        transform: translate(10px, -50%);
        position: absolute;
        left: 50%;
        top: 50%;
      }
    }

    .label {
      margin-bottom: 4px;
      font-size: 16px;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
    }

    .date {
      font-size: 14px;
      text-align: center;
      color: rgba(0, 0, 0, 0.85);
    }
  }
}
