@import 'src/global/constants.scss';

.container {
  .tableRow {
    display: flex;
    border-bottom: 1px solid $basicColor4;

    .tableTitle {
      flex: 1 1;
      padding: 0px $padding2;
      height: 60px;
      background-color: $basicColor2;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  .addButtonContainer {
    padding-top: $padding2;
  }
}
