@import 'src/global/constants.scss';

.container {
}

.content {
  height: calc(100vh - 60px - 45px - 60px);
  padding: $padding4 $padding6 $padding3;
  overflow-y: auto;
}

.paddingBottom {
  padding-bottom: $padding3;
}

.listContainer {
  margin-left: -$padding6;
  margin-right: -$padding6;
}
