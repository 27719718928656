@import 'src/global/constants.scss';

.modalContent {
  padding: 16px;

  .title {
    padding-bottom: 9px;
  }

  .content {
    padding-bottom: 9px;
    word-break: break-all;
  }

  .buttonContainer {
    display: flex;
    justify-content: center;
  }
}
