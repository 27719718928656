@import 'src/global/constants.scss';

.container {
}

.title {
  padding-bottom: $padding2;
  font-size: 16px;
}

.inputContainer {
  padding-bottom: $padding3;
}

.buttons {
  display: flex;
  justify-content: center;

  & > .buttonItem:first-child {
    margin-right: $padding2;
  }

  .button {
    width: 180px;
  }

  .label {
    padding-top: 15px;
    font-size: 16px;
    text-align: center;
  }

  .failButton {
    background-color: $basicColor13;
    color: $basicColor1;

    &:hover {
      background-color: $basicColor10;
      color: $basicColor1;
    }
  }
}
