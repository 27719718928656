@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding3;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.functionContainer {
  padding: 0 $padding4 $padding1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tableContainer {
  max-height: calc(100vh - 60px - 80px - 42px - 52px - $padding3);
  overflow-y: auto;
}

.operate {
  display: flex;
  align-items: center;

  .button {
    padding: 0px;
  }
}
