@import 'src/global/constants.scss';

#customTable {
  .ant-table-tbody .ant-table-cell {
    &:has(> .selected) {
      background-color: $themeColor1;
    }
  }

  .ant-table-cell {
    word-break: break-all;
  }

  .ant-table-cell::before {
    opacity: 0;
  }

  .ant-table-thead th,
  .ant-table-thead td {
    border-bottom-color: transparent;
  }

  &.hasPadding .ant-table-content > table {
    padding: 0px $padding4;
  }
}
