@import 'src/global/constants.scss';

.tags {
  margin-bottom: -8px;

  .tag {
    margin-bottom: 8px;
  }
}

.breakAll {
  word-break: break-all;
}

.operateButton {
  margin-left: 15px;
  padding: 4px 0px;
}
