@import 'src/global/constants.scss';

.select {
  min-width: 50px;
}

.label {
  padding: 0px 7px;
  min-height: 32px;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
}
