@import 'src/global/constants.scss';

.container {
  padding-bottom: $padding3;
  // min-height: 370px;
}

.titleContainer {
  padding: 0px $padding6 $padding2;
  display: flex;
  align-items: center;
  position: relative;

  .title {
    display: flex;
    align-items: center;
    font-size: 16px;
  }

  .button {
    padding: 6px 10px;
    height: fit-content;
    display: flex;
    align-items: center;

    .icon {
      font-size: 18px;
    }
  }

  .exportButtonContainer {
    position: absolute;
    right: $padding6;
  }
}

.tableContainer {
  display: none;

  &.show {
    display: block;
  }

  .row {
    display: flex;
    align-items: center;
  }

  .interviewer {
    padding-right: $padding1;
  }
}
